import { get } from "./utils"

export const getImageData = (image, additionalParams) => {
  let origUrl = get(image, "url", "")
  if (additionalParams) {
    origUrl = transformImage(origUrl, additionalParams)
  }
  return {
    url: origUrl || "",
    alt: get(image, "description", ""),
    width: get(image, "width", "auto"),
    height: get(image, "height", "auto"),
  }
}

export const transformImage = (origUrl, additionalParams) => {
  return additionalParams ? `${origUrl}${additionalParams}` : origUrl
}

export const convertToWebp = origUrl => {
  const imageQualitySize = 80
  const hasAdditionalParams = !/.(png|gif|jpg|jpeg|svg)$/.test(origUrl)

  const concatenationSymbol = hasAdditionalParams ? "&" : "?"

  const isSvg = /.svg/.test(origUrl)

  if (typeof window === "undefined") {
    return isSvg
      ? `${origUrl}${concatenationSymbol}q=${imageQualitySize}`
      : `${origUrl}${concatenationSymbol}fm=webp&q=${imageQualitySize}`
  }

  const newVariable = window.navigator
  const isSafari = /^((?!chrome|android).)*safari/i.test(newVariable.userAgent)
  const isIE = newVariable.pointerEnabled

  return isIE || isSafari || isSvg
    ? `${origUrl}${concatenationSymbol}q=${imageQualitySize}`
    : `${origUrl}${concatenationSymbol}fm=webp&q=${imageQualitySize}`
}
