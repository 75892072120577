export const labels = {
  urls: {
    homepage: "/",
  },
  pageTitle: "Privacy Policy - Mondelez International",
  videoNotSupported: "Your browser does not support the video tag.",
}
export const homeBreadcrumb = {
  title: "Home",
  link: "https://www.mondelezinternational.com/",
  separatedBy: '/'
};
