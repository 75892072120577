import PropTypes from "prop-types"
import React from "react"
import SeoHead from "../../Scripts/SeoHead"

const ScriptWrapper = props => {
  const { seoHead } = props

  return (
    <>
      <SeoHead {...seoHead} />
    </>
  )
}

ScriptWrapper.propTypes = {
  seoHead: PropTypes.object,
}

export default ScriptWrapper
