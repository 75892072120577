// /**
//  * Layout component that queries for data
//  * with Gatsby's useStaticQuery component
//  *
//  * See: https://www.gatsbyjs.com/docs/use-static-query/
//  */

import PropTypes from "prop-types"
import * as React from "react"
import useScreenSize from "../../hooks/useScreenSize"
import "../../styles/fonts.css"
import BreadCrumb from "../BreadCrumb/BreadCrumb"
import Image from "../UI/Image/Image"
import ScriptWrapper from "../Wrappers/ScriptWrapper/ScriptWrapper"
import Link from "../UI/Link/Link"
import { homeBreadcrumb } from "../../constants/label.constants"

const Layout = ({ children, seoData, headerImages, homeBreadcrumbTitle }) => {
  const { images = {} } =
    headerImages?.data?.allContentfulHeaderImages?.nodes[0] || {}

  const screen = useScreenSize()
  const foregroundImg = screen?.isDesktop
    ? images?.imageDesktop
    : images?.imageMobile
  const backgroundImg = screen?.isDesktop ? images?.bgDesktop : images?.bgMobile

  const headerBgStyle = {}
  if (backgroundImg) {
    headerBgStyle.backgroundImage = `url(${backgroundImg?.url})`
  }

  const headerClasses = {
    headerContainer:
      "headerContainer h-[160px]  mt-[-10] z-[99]  relative bg-repeat mt-[-10px]  flex justify-start bg-violetDark lg:bg-transparent pl-20",
    logoWrapper: "logoWrapper  list-none sm:mt-[40px] mt-[62px]",
    logo: "logo w-full max-w-[115px] h-auto lg:max-w-[212px] h-auto inline-block w-[212px]",
  }

  return (
    <>
      <ScriptWrapper seoHead={seoData} />
      <div
        media="print"
        className="main-container sm:w-auto text-darkBlue proximaNova-Regular,serif"
      >
        {headerImages && (
          <div style={headerBgStyle} className={headerClasses?.headerContainer}>
            <div className={headerClasses?.logoWrapper}>
              <Link url={homeBreadcrumb.link} title={homeBreadcrumb.title}>
                <Image
                  src={foregroundImg?.url}
                  alt={headerImages?.altText}
                  className={headerClasses?.logo}
                  width={212}
                  height={80}
                />
              </Link>
            </div>
          </div>
        )}
        <BreadCrumb
          homeBreadcrumbTitle={homeBreadcrumbTitle}
          breadcrumbTitle={seoData?.breadcrumbTitle}
        />
        <div className="content-container w-full">
          <main>{children}</main>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoData: PropTypes.object,
  homeBreadcrumbTitle: PropTypes.string,
  globalComponents: PropTypes.array,
  headerImages: PropTypes.object,
}

export default Layout
