import PropTypes from "prop-types"
import React from "react"
import Image from "../Image/Image"

const Link = props => {
  const {
    label,
    url,
    target,
    title,
    className = "",
    children,
    onFocus,
    onBlur,
    id,
    scrollToElementId,
    gaEventClass,
    gaEventLabel = "",
    image,
  } = props

  const regex = new RegExp(
    "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
  )
  const isExternallink = regex.test(url)

  let pageUrl
  if (url) {
    pageUrl = isExternallink || url?.trim()?.endsWith("/") ? url : `${url}/`
  }
  let href = pageUrl
  if (pageUrl && scrollToElementId) {
    href = `${pageUrl}#${scrollToElementId}`
  } else if (!pageUrl && scrollToElementId) {
    href = `#${scrollToElementId}`
  }

  const targetWindow = target || (isExternallink ? "_blank" : "_self")

  // if image is there, pass down event to image element
  const eventClassFinal =
    gaEventClass || isExternallink
      ? "event_external_link"
      : "event_internal_link"

  const classNameFinal = [className, eventClassFinal].join(" ")

  const content = image ? (
    <Image
      contentfulImage={image}
      eventClass={gaEventClass}
      eventLabel={gaEventLabel}
    />
  ) : (
    label
  )

  if (!url && !scrollToElementId) {
    return <div className={classNameFinal}>{content}</div>
  }

  return (
    <a
      href={href}
      target={targetWindow}
      className={classNameFinal}
      onFocus={onFocus}
      onBlur={onBlur}
      data-action-detail={gaEventLabel}
      title={title}
      id={id}
    >
      {children || content}
    </a>
  )
}

Link.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  scrollToElementId: PropTypes.string,
  image: PropTypes.object,
  id: PropTypes.string,
  gaEventClass: PropTypes.string,
  gaEventLabel: PropTypes.string,
  onClick: PropTypes.func,
}

export default Link
