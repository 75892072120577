import PropTypes from "prop-types"
import React from "react"
import Link from "../UI/Link/Link"
import { style } from "./BreadCrumb-tw-styles"
import { homeBreadcrumb } from "../../constants/label.constants"

const BreadCrumb = props => {
  const { homeBreadcrumbTitle, breadcrumbTitle } = props
  
  return (
    <div className={style.container}>
      <ul className={style.breadcrumbItems}>
        <li className={style.breadcrumbItemsStyle}>
          <Link
            label={homeBreadcrumbTitle}
            to={homeBreadcrumb.link}
            url={homeBreadcrumb.link}
            target={"_self"}
            className={style.event_external_link}
          />
          <span className={style.breadcrumbDevider}>
            {" "}
            {homeBreadcrumb.separatedBy}
          </span>
        </li>
        <h1 className={style?.heading}>{breadcrumbTitle}</h1>
      </ul>
    </div>
  )
}

BreadCrumb.propTypes = {
  breadCrumbs: PropTypes.object,
  breadcrumbTitle: PropTypes.string,
  homeBreadcrumbTitle: PropTypes.string
}

export default BreadCrumb
